import { Group, GroupMember, User } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

export const groupAction = createActionGroup({
  source: '[group]',
  events: {
    'fetch group': emptyProps(),
    'fetch group success': props<{ data: Group[] }>(),
    'update loader': props<{ isLoading: boolean }>(),
    'update success': props<{ data: Update<Group> }>(),
    'add member': props<{
      data: { groupId: Group['id']; userId: string };
    }>(),

    delete: props<{ id: Group['id'] }>(),
    'delete success': props<{ id: Group['id'] }>(),

    'delete member': props<{ id: GroupMember['id'] }>(),
    'delete member success': props<{
      id: string;
      groupId: string;
      user?: User;
    }>(),
    add: props<{ data: Group; ref?: string }>(),
    update: props<{
      data: Group;
      id: Group['id'];
      message?: string;
      ref?: string;
    }>(),
    'add success': props<{ data: Group }>(),
    'fetch dissociate user': emptyProps(),
    'fetch dissociate user success': props<{ data: User[] }>(),
  },
});
